import React, { useState } from 'react';
import { Redirect, useRouteMatch } from 'react-router-dom';
import { verifyAutoKey } from '../services/authentication';
import Loader from '../../components/Loader';

const Index = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFetched, setIsFetched] = useState(false);
  const match = useRouteMatch('/auto-login/:key');
  const redirectTo = new URL(window.location.href).searchParams.get('redirect_to');
  const res = verifyAutoKey({ key: match.params.key });
  res
    .then((res) => {
      console.log(res.status);
      res.message === match.params.key ? setIsSuccess(true) : setIsSuccess(false);
      setIsFetched(true);
    })
    .catch((e) => {
      console.log(e);
      setIsFetched(true);
    });
  return !isFetched ? (
    <Loader />
  ) : isSuccess ? (
    <Redirect to={redirectTo} />
  ) : (
    <Redirect to={'/signin'} />
  );
};

export default Index;
